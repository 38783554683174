@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

*:focus {
  outline: 0 !important;
}

html,
body,
#root {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialased;
}

body,
input,
button {
  font: 1rem 'Roboto', sans-serif;
}

button {
  cursor: pointer;
}
.a-clean,
.a-clean:hover,
.a-clean:active {
  color: inherit;
  text-decoration: inherit;
}

.Toastify__toast--success {
  background-color: #ff0000;
  border-radius: 4px;
  text-align: center;
}
.Toastify__toast--error {
  background-color: #ff0000;
  border-radius: 4px;
  text-align: center;
}
.Toastify__toast--info {
  background-color: #ff0000;
  border-radius: 4px;
  text-align: center;
}
.Toastify__toast--warning {
  background-color: #ff0000;
  border-radius: 4px;
  text-align: center;
}

.f-16 {
  font-size: 16px;
}

.f-14 {
  font-size: 14px;
}

.bg-black {
  background-color: black !important;
}

.f-black {
  color: black;
}

.f-white {
  color: white;
}

.f-blue {
  color: #ff0000;
}

.btn-primario {
  margin: 5px 0 0;
  height: 44px;
  background-color: #ff0000;
  font-weight: bold;
  color: white;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}
/*
.btn-primario:hover {
  background-color: white;
  color: #E04F66;
  border: 1px solid #E04F66;
} */

.btn-secundario {
  margin: 5px 0 0;
  height: 44px;
  background-color: black;
  font-weight: bold;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
}

.btn-secundario:hover {
  background-color: #a6b438;
}

.f-12 {
  font-size: 12px !important;
}

.f-18 {
  font-size: 18px !important;
}

.f-white {
  color: white;
}

.f-green {
  color: green;
}

.f-red {
  color: red;
}

.hr-loja {
  width: 100%;
  border-top: 3px solid #ff0000;
}

ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

ol > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

ol > li:before {
  content: counters(item, '.') '. ';
  display: table-cell;
  padding-right: 0.6em;
}

li ol > li {
  margin: 0;
}

li ol > li:before {
  content: counters(item, '.') ' ';
}

.row:before,
.row:after {
  display: none !important;
}

.caret-4-desc:after {
  content: '\2193';
}
.caret-4-desc:before {
  margin-left: 3.5px;
  content: '\2191';
  opacity: 0.4;
}

.caret-4-asc:after {
  content: '\2193';
  opacity: 0.4;
}
.caret-4-asc:before {
  margin-left: 3.5px;
  content: '\2191';
}

ul.pagination.react-bootstrap-table-page-btns-ul {
  margin-top: 1rem;
}

.btn-limpo {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

div.ril-toolbar.ril__toolbar {
  background-color: unset;
}

.table-dark thead th {
  color: white;
}

.table-dark td {
  background-color: white;
  color: black;
  border-color: #e4e7e9 !important;
}

.table-dark td:focus {
  outline: none !important;
  border: 10px solid black;
}

.table-dark tfoot {
  background-color: white;
  color: black;
}

.table-dark {
  white-space: nowrap;
  font-size: 14px;
  background-color: #777777 !important;
}

.barra-busca {
  float: right !important;
  border: none;
  outline: 0 !important;
  width: 150px !important;
  color: black;
}

.textarea {
  padding: 10px !important;
  min-height: 100px;
}

.input-select {
  width: auto;
  height: 35px;
  border: 1px solid #ff0000;
  border-radius: 4px;
  background-color: white;
}

.input-theme {
  height: 35px !important;
  border: 1px solid #ff0000 !important;
  border-radius: 4px !important;
  background-color: white !important;
  outline: none !important;
  color: black !important;
  box-shadow: none !important;
}

.input-theme::placeholder {
  color: black !important;
}

input,
select {
  padding-left: 16px !important;
}

.react-select__placeholder {
  padding-left: 15px;
}

.btn-busca {
  height: 35px;
  width: 35px;
  background-color: #ff0000;
  font-weight: bold;
  color: white;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 25px;
}

@media screen and (min-width: 576px) {
  .btn-busca {
    height: 35px;
    width: 35px;
    background-color: #ff0000;
    font-weight: bold;
    color: white;
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 25px;
  }
}

.search-label {
  max-width: 250px;
}

@media screen and (max-width: 768px) {
  .search-label {
    width: 100% !important;
    max-width: 100%;
  }
}

.btn-primario-slim {
  height: 35px;
  background-color: #ff0000;
  font-weight: bold;
  color: rgb(255, 255, 255);
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
}

@media screen and (min-width: 576px) {
  .btn-primario-slim {
    height: 35px;
    background-color: #ff0000;
    font-weight: bold;
    color: rgb(255, 255, 255);
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 25px;
    padding-left: 5px;
    padding-right: 5px;
    margin: 0;
  }
}

.alert-antifraude,
.alert-antifraude:hover,
.alert-antifraude:visited {
  font-size: 14px;
  margin-left: 5px;
  color: red !important;
  text-decoration: none;
}

.link-clean,
.link-clean:hover,
.link-clean:visited {
  color: black;
}

.form-control.editor.edit-text:focus {
  box-shadow: none;
  border: 1px solid #ff0000;
}

.premio-desc {
  min-height: 100px;
}

select {
  color: #ff0000;
}

/* Estilo para os botões dos modais */
.modal-footer .btn-primary {
  background-color: #ff0000 !important;
  border-color: #ff0000 !important;
  color: white !important;
}

.modal-footer .btn-primary:hover {
  background-color: #ff0000 !important;
  border-color: #ff0000 !important;
}

.modal-footer .btn-secondary {
  background-color: #777777 !important;
  border-color: #777777 !important;
  color: white !important;
}

.modal-footer .btn-secondary:hover {
  background-color: #666666 !important;
  border-color: #666666 !important;
}

/* Estilo para o cabeçalho do modal */
.modal-header {
  border-bottom: 3px solid #ff0000 !important;
}

/* Estilo para inputs e textareas dentro dos modais */
.modal-body .form-control:focus {
  border-color: #ff0000 !important;
  box-shadow: 0 0 0 0.2rem rgba(170, 192, 0, 0.25) !important;
}

.modal-body .text-primary {
  color: #ff0000 !important;
}

.modal-body .text-primary:hover {
  color: #ff0000 !important;
}

input[type="number"] {
  color: #000 !important;
  -webkit-text-fill-color: #000 !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: auto !important;
  opacity: 1 !important;
  margin-left: 5px;
}

